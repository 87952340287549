  <template>

  <travio-center-container grid-width='1/2'>
    <h2 class="mb-4" style="color:#636363">{{formTitle}}</h2>
    <vx-card>
      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Name*</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.name" v-validate="'required|max:100'" name="Name" />
          <span class="text-danger text-sm">{{ errors.first('Name') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Restrictions</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" v-model="form.restrictions" v-validate="'required|max:500'" name="Restrictions" />
          <span class="text-danger text-sm">{{ errors.first('Restrictions') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Estimated Delivery Days</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.estimateDays" name="Estimated Delivery Days" :options="deliveryEstimateOptions" :reduce="(x) => x.code" v-validate="'required'" />
          <span class="text-danger text-sm">{{errors.first("Estimated Delivery Days")}}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Price</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <vs-input class="w-full" type="number" min="0" name="Price" v-model.number="form.price" v-validate="'required|decimal'" />
          <span class="text-danger text-sm">{{ errors.first('Price') }}</span>
        </div>
      </div>

      <div class="vx-row mb-4">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Currency</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full">
          <v-select v-model="form.currency" name="Currency" :options="currencyOptions" :reduce="(x) => x.code" v-validate="'required'" />
            <span class="text-danger text-sm">{{errors.first("Currency")}}</span>
        </div>
      </div>

      <div class="vx-row mb-6">
        <div class="vx-col sm:w-1/5 w-full">
          <span>Active</span>
        </div>
        <div class="vx-col sm:w-4/5 w-full ml-auto">
          <vs-switch class="" v-model="form.isActive" />
        </div>
      </div>

      <div class="flex flex-wrap justify-end">
          <vs-button class="" color="danger" @click="onCancel" type="filled">Cancel</vs-button>      
          <vs-button class="ml-2" color="primary" @click="onSave" type="filled">Save</vs-button>      
      </div>
    </vx-card>

  </travio-center-container>
</template>

<script>
import { Form } from '@/core-modules/form-framework/Form.js'
import useAppFeatureAccess from '@/components/travio-pro/useAppFeatureAccess.js';

export default {
  components: {
  },
  props: {
    applicationId: { required: true },
    deliveryFeeId: { required: false, default: 0 },
  },
  data () {
    return {
      form: new Form({
        id: 0,
        name: '',
        restrictions: '',
        estimateDays: null,
        price: null,
        currency: '',
        isActive: true,
      }),
      currencyOptions: [],
      deliveryEstimateOptions: [],

    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser;
    },
    formTitle() {
      return this.deliveryFeeId == 0 ? 'Add Delivery Fee' : 'Edit Delivery Fee'
    },
  },
  setup () {
    const { appHasFeature } = useAppFeatureAccess();
    return { appHasFeature }
  },
  async created () {
    //Check if user has acccess to the app
    if( !(this.activeUserInfo.applications && this.activeUserInfo.applications.find(x => x.id == this.applicationId)) ) {
      this.$router.push('/error-404')
    }

    if (!this.appHasFeature('currencyexchange')) {
      this.$router.push('/error-404')
    }
    
    for (let i = 1; i <= 14; i++) {
      this.deliveryEstimateOptions.push({ label: `${i}`, code: i })
    }
    
    try {
      const formDataPromise = this.$http.get(`api/currencyExchanges/apps/${this.applicationId}/deliveryFees/formdata`)
      if (this.deliveryFeeId) {
        this.$vs.loading()
        const deliveryFeePromise = await this.$http.get(`api/currencyExchanges/apps/${this.applicationId}/deliveryFees/${this.deliveryFeeId}`)
        const [deliveryFee, formData] = await Promise.all([deliveryFeePromise, formDataPromise])
        this.currencyOptions = formData.data.currencies
        this.form = new Form({...deliveryFee.data})
      } else {
        this.$vs.loading()
        const formData = await formDataPromise
        this.currencyOptions = formData.data.currencies
        this.$vs.loading.close()
      }
      
    } catch (error) {
      this.$_notifyFailure(error)
    } 
    this.$vs.loading.close();


  },
  mounted() {

  },
  methods: {
    async onSave (){

      const validationResult = await this.$validator.validateAll()
      if (!validationResult) {
        this.$_notifyFailure('Invalid form data. Please correct all form fields.')
        return
      }

      this.$vs.loading()
      if (!this.deliveryFeeId) {
        try {
          await this.$http.post(`api/currencyExchanges/apps/${this.applicationId}/deliveryFees`, this.form.data())
          this.$_notifySuccess('Successfully added branch');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      } else {
        try {
          await this.$http.put(`api/currencyExchanges/apps/${this.applicationId}/deliveryFees`, this.form.data())
          this.$_notifySuccess('Successfully updated branch');
        } catch (error) {
          this.$_notifyFailure(error)
        }
      }
      
      this.$vs.loading.close();
      this.onCancel()

      
    },
    onCancel () {
      this.$router.push({name: 'application-currency-exchange-delivery-fees', 
        params: { applicationId: this.applicationId }
      })
    },
  }
}
</script>

<style>

</style>
